import React from 'react';
import { Grid, Link, Paper, Container } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import GoogleFontLoader from 'react-google-font-loader';
import Typography from '@material-ui/core/Typography';
import { faPython, faReact } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {

    let theme = createMuiTheme({
        typography: {
            fontFamily: "DM Serif Display",
        },
    });

    return (
        <Container>
            <Grid container alignItems="center" justify="flex-end" direction="row" style={{ marginTop: "25px", marginBottom: "30px", marginLeft: "2px", marginRight: "2px" }}>
                    <Typography variant="subtitle1" > Built with <FontAwesomeIcon icon={faReact} style={{ color: "#61DBFB", marginRight: "5px", marginLeft: "5px" }} /> & <FontAwesomeIcon icon={faPython} style={{ color: "#fbb901", marginRight: "5px", marginLeft: "5px" }} /> by <Link href="http://tanmoysg.com" target="_blank" color="inherit" style={{fontWeight: "600"}}>Tanmoy Sen Gupta</Link></Typography>
            </Grid>
        </Container>
    );
}

export default Footer;
